import React, { useEffect, useState } from 'react';
import { Select, MenuItem, TextField, Button } from '@mui/material';
import { pokerPositions } from '../utils/poker-positions';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { useForm } from "react-hook-form";
import { SubmitHandler } from 'react-hook-form/dist/types';
import { useMutation } from 'react-query';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ICreateHand } from '../interfaces/create-hand.interface';
import { useNavigate, useParams } from 'react-router-dom';

type Inputs = {
    position: string,
    stackSize: number,
    holeCards: string,
    preFlopNotes: string,
    foldedPreflop: boolean,
    flopCards: string,
    flopNotes: string,
    foldedFlop: boolean,
    turnCard: string,
    turnNotes: string,
    foldedTurn: boolean,
    riverCard: string,
    riverNotes: string,
    foldedRiver: boolean,
    wonHand: boolean,
    otherNotes: string,
    sessionId: string
}

export const HandRecorderForm = (props: any) => {
    let { id } = useParams();
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors }, reset } = useForm<Inputs>();
    const [hand, setHand] = useState<ICreateHand>();
    const userId = localStorage.getItem("userId");
    const sessionId = localStorage.getItem("sessionId");

    useEffect(() => {
        if (id) {
            fetch(`https://nh4tl0ai74.execute-api.us-west-1.amazonaws.com/develop/hand-tracker/${id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then(response => response.json())
            .then((data) => {
                setHand(data);
            });
        }
    });

    
    const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
        console.log("form submit event: ", data);

        if (sessionId) {
            data.sessionId = sessionId;
            data.wonHand = false;
            data.foldedFlop = false;
            data.foldedTurn = false;
            data.foldedRiver = false;

            console.log(data);

            const response = await fetch("https://nh4tl0ai74.execute-api.us-west-1.amazonaws.com/develop/hand-tracker", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });

            const json = (await response.json()) as ICreateHand;

            if (json.id) {
                console.log("Saved successfully!");
                reset();
            }
        }
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl>
                <InputLabel id="demo-position-label" margin='dense'>Your Position</InputLabel>
                <Select
                    labelId="demo-position-label"
                    label="Position"
                    id="position-select"
                    required
                    margin='dense'
                    {...register("position")}
                >
                    {pokerPositions.map((position) => 
                        (<MenuItem value={position.position}>{position.name}</MenuItem>)
                    )}
                </Select>
                <TextField 
                    id="stack-size-text-field"
                    {...register("stackSize")}
                    value={hand?.stackSize}
                    label={hand?.stackSize ?? "Stack Size"}
                    required
                    type="number"
                    margin="dense" />
                <TextField 
                    id="hole-cards-text-field"
                    {...register("holeCards")}
                    value={hand?.holeCards}
                    label={hand?.holeCards ?? "Hole Cards"}
                    required
                    margin="dense" />
                <TextField 
                    id="pre-flop-notes-text-field"
                    {...register("preFlopNotes")}
                    label="Pre-Flop Notes"
                    margin="dense" />
                    <FormControlLabel
                        control={
                            <Checkbox 
                            id="folded-preflop-checkbox" 
                            {...register("foldedPreflop")}
                            title="Folded Pre-flop"
                            name="folded-preflop-checkbox"
                            />
                        }
                        label="Folded Pre-flop"
                    />
                <TextField 
                    id="flop-cards-text-field"
                    {...register("flopCards")}
                    label="Flop Cards"
                    margin="dense" />
                <TextField 
                    id="flop-notes-text-field"
                    {...register("flopNotes")}
                    label="Flop Notes"
                    margin="dense" />
                <TextField 
                    id="turn-card-text-field"
                    {...register("turnCard")}
                    label="Turn Card"
                    margin="dense" />
                <TextField 
                    id="turn-card-notes-text-field"
                    {...register("turnNotes")}
                    label="Turn Notes"
                    margin="dense" />
                <TextField 
                    id="river-card-text-field"
                    {...register("riverCard")}
                    label="River Card"
                    margin="dense" />
                <TextField 
                    id="river-card-notes-text-field"
                    {...register("riverNotes")}
                    label="River Notes"
                    margin="dense" />
                <TextField
                    id="other-notes-text-area"
                    {...register("otherNotes")}
                    label="Other Notes"
                    margin="dense"
                    />
                <Button variant="outlined" 
                onClick={() => reset({
                    otherNotes: "",
                    riverCard: "",
                    riverNotes: "",
                    foldedFlop: false,
                    foldedPreflop: false,
                    foldedRiver: false,
                    foldedTurn: false,
                    flopCards: "",
                    flopNotes: "",
                    turnCard: "",
                    turnNotes: "",
                    stackSize: 0,
                    position: "",
                    preFlopNotes: "",
                    holeCards: "",
                    wonHand: false,
                    
                })}>
                    Clear Hand</Button>
                <Button
                    variant="contained"
                    type="submit"
                >Save Hand</Button>
                <Button onClick={()=>navigate(`/session/${sessionId}`)}>Back to Session</Button>
                <Button onClick={()=>navigate(`/hand-list/${sessionId}`)}>View Hands</Button>
            </FormControl>
        </form>
    );
}