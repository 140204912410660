import React, { useEffect, useState } from 'react';
import { Select, MenuItem, TextField, InputLabel, IconButton } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ICreateSessionResponse } from '../interfaces/create-session.interface';

type Inputs = {
    stackSize: number,
    userId: string,
    location: string,
    smallBlind: number,
    bigBlind: number,
    cashOutAmount: number,
}

export const Session = (props: any) => {
    let { id } = useParams();
    const navigate = useNavigate();
    const [session, setSession] = useState<ICreateSessionResponse>();
    const userId = localStorage.getItem("userId");

    useEffect(() => {
        if (userId && id) {
            fetch(`https://nh4tl0ai74.execute-api.us-west-1.amazonaws.com/develop/session/${id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then(response => response.json())
            .then((data) => {
                setSession(data);
            });
        }
    }, []);

    const { register, handleSubmit, watch, formState: { errors } } = useForm<Inputs>();
    
    const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
        console.log("form submit event: ", data);
        const userId = localStorage.getItem("userId");

        if (userId) {
            data.userId = userId;
            const response = await fetch("https://nh4tl0ai74.execute-api.us-west-1.amazonaws.com/develop/session", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });
    
            const json = (await response.json()) as ICreateSessionResponse;

            if (json.id) {
                localStorage.setItem("sessionId", json.id);
                navigate("/hand-recorder");
            }
        }
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl>
                <TextField 
                    id="stack-size-text-field"
                    {...register("stackSize")}
                    value={session?.stackSize}
                    label={session?.stackSize ?? "Stack Size"}
                    required
                    type="number"
                    margin="dense" />
                <TextField 
                    id="small-blind-text-field"
                    {...register("smallBlind")}
                    value={session?.smallBlind}
                    label={session?.smallBlind ?? "Small Blind"}
                    type="number"
                    margin="dense" />
                <TextField 
                    id="big-blind-text-field"
                    {...register("bigBlind")}
                    value={session?.bigBlind}
                    label={session?.bigBlind ?? "Big Blind"}
                    type="number"
                    margin="dense" />
                <TextField
                    id="location-text-area"
                    {...register("location")}
                    value={session?.location}
                    label={session?.location ?? "Location"}
                    margin="dense"
                    />
                <TextField
                    id="cash-out-amount-text-field"
                    {...register("cashOutAmount")}
                    value={session?.cashOutAmount}
                    label={session?.cashOutAmount ?? "Cash Out Amount"}
                    type="number"
                    margin="dense"
                    disabled={!!id}
                    />
                <Button variant="outlined" >Clear Session</Button>
                <Button
                    variant="contained"
                    type="submit"
                >Save Session</Button>
                <Button disabled={!id} onClick={() => navigate('/hand-recorder')}>Start Recording Hands</Button>
            </FormControl>
        </form>
    );
}