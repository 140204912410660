import React, { useEffect, useState } from 'react';
import { Table, TableContainer, Paper, TableBody, TableHead, TableRow, TableCell, Tab, Button } from '@mui/material';
import { NavLink, useNavigate } from 'react-router-dom';
import { ICreateSessionResponse } from '../interfaces/create-session.interface';

export const SessionList = (props: any) => {
    const navigate = useNavigate();
    const [sessions, setSession] = useState<ICreateSessionResponse[]>([]);
    const userId = localStorage.getItem("userId");

    useEffect(() => {
        if (userId) {
            fetch(`https://nh4tl0ai74.execute-api.us-west-1.amazonaws.com/develop/session/user/${userId}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then(response => response.json())
            .then((data: ICreateSessionResponse[]) => {
                console.log(data[0].created);
                setSession(data);
            });
        } else {
            navigate('/')
        }
    }, []);

    return (
        <>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Session Date</TableCell>
                            <TableCell>Location</TableCell>
                            <TableCell>Blinds</TableCell>
                            <TableCell>Buy-In</TableCell>
                            <TableCell>Cashout Amount</TableCell>
                            <TableCell>View Hands</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sessions?.map((row) => (   
                            <TableRow key={row.id}>
                                <TableCell><NavLink to={`/session/${row.id}`}>{row.created?.toString()}</NavLink></TableCell>
                                <TableCell>{row.location}</TableCell>
                                <TableCell>${row.smallBlind}/${row.bigBlind}</TableCell>
                                <TableCell>{row.stackSize}</TableCell>
                                <TableCell>{row.cashOutAmount}</TableCell>
                                <TableCell><NavLink to={`/hand-list/${row.id}`}>View Hands</NavLink></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Button variant="outlined" onClick={() => navigate("/session")}>Create New Session</Button>
        </>
    );
}