import React from 'react';
import { Box, TextField, FormControl } from '@mui/material';
import Button from '@mui/material/Button';
import { SubmitHandler } from 'react-hook-form/dist/types';
import { useForm } from "react-hook-form";
import { IUserLogin } from '../interfaces/user-login.interface';
import { useNavigate } from 'react-router-dom';

type Inputs = {
    email: string,
    password: string
}

export const Login = (props: any) => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm<Inputs>();
    const navigate = useNavigate();
    
    const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
        console.log("form submit event: ", data);
        const response = await fetch(`https://nh4tl0ai74.execute-api.us-west-1.amazonaws.com/develop/user?email=${data.email}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });

        const json: IUserLogin[] = (await response.json()) as IUserLogin[];

        if (Array.isArray(json) && json.length > 0) {
            localStorage.setItem("userId", json[0].id);
            navigate("/session-list");
        }
    }
    return (
        <form  onSubmit={handleSubmit(onSubmit)}>
            <FormControl>
                <TextField
                    id="email-text-field"
                    label="Email"
                    required
                    type="email"
                    {...register("email")}
                    margin="dense" />
                <TextField
                    id="password"
                    {...register("password")}
                    required
                    type="password"
                    />
                <Button 
                    variant="contained"
                    type="submit"
                >Log in</Button>
            </FormControl>
        </form>
    );
}