import { Routes, Route } from 'react-router-dom'
import logo from './logo.svg';
import './App.css';
import { Login } from './components/login';
import { Session } from './components/session';
import { HandRecorderForm } from './components/hand-recorder';
import { HandList } from './components/hands-list';
import { HandDetail } from './components/hand-detail';
import { SessionList } from './components/session-list';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/hand-recorder" element={<HandRecorderForm />} />
      <Route path="/session-list" element={<SessionList />} />
      <Route path="/session" element={<Session />} />
      <Route path="/session/:id" element={<Session />} />
      <Route path="/hand-list/:sessionId" element={<HandList />} />
      <Route path="/hand-detail/:id" element={<HandDetail />} />
    </Routes>
  );
}

export default App;
