import React, { useEffect, useState } from 'react';
import { Table, TableContainer, Paper, TableBody, TableHead, TableRow, TableCell, Tab, Button } from '@mui/material';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { ICreateSessionResponse } from '../interfaces/create-session.interface';
import { ICreateHand } from '../interfaces/create-hand.interface';

export const HandList = (props: any) => {
    let { sessionId } = useParams();
    const navigate = useNavigate();
    const [hands, setHands] = useState<ICreateHand[]>([]);
    const userId = localStorage.getItem("userId");

    useEffect(() => {
        if (sessionId && userId) {
            localStorage.setItem("sessionId", sessionId);
            fetch(`https://nh4tl0ai74.execute-api.us-west-1.amazonaws.com/develop/hand-tracker/session/${sessionId}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then(response => response.json())
            .then((data: ICreateHand[]) => {
                console.log(data);
                setHands(data);
            });
        } else {
            navigate('/')
        }
    }, []);

    return (
        <>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Hand Time</TableCell>
                            <TableCell>Starting Stack</TableCell>
                            <TableCell>Position</TableCell>
                            <TableCell>Hole Cards</TableCell>
                            <TableCell>Pre-Flop Notes</TableCell>
                            <TableCell>Flop Cards</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {hands?.map((row) => (   
                            <TableRow key={row.id}>
                                <TableCell><NavLink to={`/hand-detail/${row.id}`}>{row.created?.toString()}</NavLink></TableCell>
                                <TableCell>{row.stackSize}</TableCell>
                                <TableCell>{row.position}</TableCell>
                                <TableCell>{row.holeCards}</TableCell>
                                <TableCell>{row.preFlopNotes}</TableCell>
                                <TableCell>{row.flopCards}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Button variant="outlined" onClick={() => navigate("/hand-recorder")}>Record New Hand</Button>
        </>
    );
}