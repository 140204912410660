export const pokerPositions:IPosition[] = [
    {
        name: "Under the Gun",
        position: "utg",
        order: 1
    },
    {
        name: "Under the Gun+1",
        position: "utg1",
        order: 2
    },
    {
        name: "Under the Gun+2",
        position: "utg2",
        order: 3
    },
    {
        name: "Lojack",
        position: "lojack",
        order: 4
    },
    {
        name: "Hijack",
        position: "hijack",
        order: 5
    },
    {
        name: "Button",
        position: "button",
        order: 6
    },
    {
        name: "Small Blind",
        position: "sb",
        order: 7
    },
    {
        name: "Big Blind",
        position: "bb",
        order: 8
    },
];

export interface IPosition {
    position: string;
    name: string;
    order: number;
}